@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */
@font-face {
    font-family: 'din';
    src: url('/fonts/din-medium.eot') format('eot'),
    url('/fonts/din-medium.woff2') format('woff2'),
    url('/fonts/din-medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.transitioning {
    transition: .3s ease all;
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotateY-180 {
    transform: rotateY(180deg);
}

body{
    @apply font-body
}

/* Global */
h1 {
    @apply relative text-2xl lg:text-3xl font-body font-medium;
}

.banner h1{
    @apply text-3xl lg:text-4xl
}
#slider-hero h1{
    @apply text-3xl xl:text-4xl xxl:text-5xl
}
h2 {
    @apply relative text-2xl ;
}

a {
    @apply inline-block cursor-pointer;
}


.bouton, .btn, input[type=submit] {
    @apply relative inline-block px-6 py-2 bg-secondary text-xl text-black font-semibold border border-solid border-secondary uppercase cursor-pointer ;
}

.bouton:hover, .btn:hover, input[type=submit]:hover {
    @apply bg-transparent text-secondary;
}

.bords {
    @apply border border-solid border-grey-300 p-4;
}

/* Formulaire */
input[type=text], input[type=email], input[type=password], input[type=tel] {
    @apply border border-solid border-primary p-1 rounded-lg;
}

input[type=checkbox]{
    min-width: 0.9rem !important;
    width: 0.9rem !important;
    height: 0.9rem  !important;
}
.rgpd label p{
    @apply text-sm
}
.form {
    @apply relative block;
}

.form input[type=text] + label, .form input[type=email] + label, .form input[type=tel] + label, .form input[type=password] + label {
    position: absolute;
    left: 3%;
    top: 1.25rem;
    pointer-events: none;
    width: 100%;
    transform: translateY(-50%);
    transition: transform 200ms ease;
    color: #9a9a9a;
    text-align: left;
}

.form input[type=text]:focus + label, .form input[type=email]:focus + label, .form input[type=tel]:focus + label, .form input[type=password]:focus + label, .form textarea:focus ~ label, .form label.freeze {
    @apply text-secondary-500 font-normal text-xs !important;
    transform: translateY(-1.5rem) !important;
}

/* Cadres */
.box-ligne {
    @apply relative border-b border-solid border-grey-300;
}

.card {
    @apply bg-white shadow-xl mb-4 py-8 px-12;
}

/* Header */
header {
    @apply  text-center bg-primary z-10 top-0 sticky;
}

header ul, header ul li {
    @apply relative list-none p-0 m-0;
}

header a {
    @apply inline-block py-2 lg:py-4 text-base xl:text-lg;
}

header.scrollingdown .top-header {
    @apply shadow-xl;
}

header.scrollingdown .logo {
    @apply hidden transitioning;
}



.content{
    padding : 0rem;
    width: 0%;
    opacity: 0;
    z-index: 0;
    transition: all 0.5s ease-in;
}

.aside-01 > div:first-child{
    z-index: 1;
}

.aside-01 a:hover {
    font-weight: normal;
}

.aside-02 > div:first-child{
    z-index: 1;
}

.aside-02 a:hover {
    font-weight: normal;
}

.aside-01:hover > .content{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    opacity: 1;
}

.aside-02:hover > .content{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    opacity: 1;
}



/*Hero page*/
.fonds-hero-page{
    background: linear-gradient(90deg,rgba(1,13,21,.9),rgba(1,13,21,.5),rgba(1,13,21,.5));
    clip-path: polygon(0% 0%,100% 0%,100% 50%,100% 100%,0% 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
}



/* Contenu */
#main {
    @apply relative;
}

#slider-hero {
    height: 25rem;

    & .swiper-slide img {
        @apply w-full h-full object-cover;
    }
}

#slider-hero, .hero-page{
    border-top: 14px solid #D1DA51;
    border-bottom: 14px solid #D1DA51;
}

#slider-hero .swiper-pagination-bullet {
    @apply h-3 w-3 border border-secondary rounded-full bg-white;
    opacity: 1;
}

#slider-hero .swiper-pagination-bullet-active {
    @apply h-4 w-4 rounded-full bg-secondary relative;
}

#slider-hero .swiper-pagination-bullet-active:before {
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @apply absolute h-6 w-6 border border-solid border-secondary rounded-full;
}

#intro .copyright{
    &::after{
         content: "";
         background: url("/img/icones/new-arrow.svg");
        @apply hidden xl:block absolute -left-10  xl:-left-24 -mt-1 w-20 h-20 text-secondary top-2
     }
}

#reference .copyright, #realisation .copyright{
    &::after{
         content: "";
        background: url("/img/icones/new-arrow.svg");
         @apply hidden sm:block absolute -left-12  xl:-left-16 mt-2 w-10 h-10 xl:w-12 xl:h-12  y-center
     }
}
.contact .copyright{
    &::after{
         content: "";
         background: url("/img/icones/new-arrow-primary.svg");
         @apply hidden xl:block absolute -left-8  xl:-left-20 -mt-1 w-14 h-14 xl:w-16 xl:h-16  y-center
     }
}

.list-subprestation ul::after{
    content: "";
    background: url("/img/icones/new-arrow.svg");
    @apply  absolute -left-14  xl:-left-20 -mt-1 w-12 h-12 xl:w-16 xl:h-16  top-2
}
#footer-top div:not(.rs)>a{
    &::after{
         content: "";
         background: url("/img/icones/new-arrow.svg");
            margin-top: -0.15rem;
         @apply absolute -left-6 w-4 h-4 text-secondary y-center
     }
}
.main-nav {
    @apply flex flex-col lg:flex-row justify-center items-center;

    & a {
        @apply text-2xl relative xl:mx-20 text-white font-semibold;
    }

    & a:before {
        content: "";
        top: 50%;
        left: -1.75rem;
        transform: translate(-50%, -50%);
        @apply absolute h-10 w-10;
    }

    & a:hover {
        @apply font-bold text-secondary transitioning;
    }
}

#slider-demo .swiper-slide > .text-content{
    @apply opacity-0;
}
#slider-demo .swiper-slide-active > .text-content{
    @apply opacity-100 transitioning;
}


.swiper-pagination-demo{
    @apply hidden;
}

.swiper-pagination-demo .swiper-pagination-bullet{
    @apply hidden bg-transparent w-auto relative transitioning;

}

.swiper-pagination-demo .swiper-pagination-bullet{
    opacity: 1 !important;
}
.swiper-pagination-demo .swiper-pagination-bullet-active{
    font-weight: 800 !important;
}
.swiper-pagination-demo .swiper-pagination-bullet {
    display: flex !important;
    align-items: center !important;
}

@screen lg{

    .swiper-pagination-demo {
        @apply flex opacity-100 justify-between items-center;
    }

    .swiper-pagination-demo .swiper-pagination-bullet{
        @apply block bg-transparent w-auto relative text-xl text-center text-primary mx-2;
    }

}


.swiper-pagination-demo .swiper-pagination-bullet:nth-child(1):before {
    /*background: url("/img/icones/print.svg") no-repeat center;*/
    background: url("/img/icones/voiture_g2.svg") no-repeat center;
    background-size: contain;
}

.swiper-pagination-demo .swiper-pagination-bullet:nth-child(2):before {
    background: url("/img/icones/vitrine.svg") no-repeat center;
    /*background: url("/img/icones/voiture.svg") no-repeat center;*/
    background-size: contain;
}

.swiper-pagination-demo .swiper-pagination-bullet:nth-child(3):before {
    background: url("/img/icones/signaletique.svg") no-repeat center;
    background-size: contain;
}

.swiper-pagination-demo .swiper-pagination-bullet:nth-child(4):before {
    background: url("/img/icones/picto-communication.svg") no-repeat center;
    background-size: contain;
}

.swiper-pagination-demo .swiper-pagination-bullet:nth-child(5):before {
    background: url("/img/icones/picto-marquage-textile.svg") no-repeat center;
    background-size: contain;
}
.swiper-pagination-demo .swiper-pagination-bullet:nth-child(6):before {
    background: url("/img/icones/autres.svg") no-repeat center;
    background-size: contain;
}

.swiper-pagination-demo .swiper-pagination-bullet:before{
    content: "";
    left: 50%;
    top: -4.75rem;
    transform: translateX(-50%);
    @apply absolute h-14 w-14;
}

.swiper-pagination-demo .swiper-pagination-bullet-active,.swiper-pagination-demo .swiper-pagination-bullet:hover{
    @apply font-bold;
}

.list-prestation{
    & .copyright::after{
         content: "";
         background: url("/img/icones/new-arrow-primary.svg");
         @apply  absolute -left-40 -top-16 w-20 h-20 text-secondary
     }
}

#slider-demo-nav .swiper-slide{
    @apply hidden justify-center items-center;
}

#slider-demo-nav .swiper-slide > div{
    @apply w-full;
}

#slider-demo-nav .swiper-slide-active{
    @apply flex transitioning;
}

#slider-demo-nav .swiper-slide{
    @apply text-center;
}

.contact-box h2{
    @apply uppercase;
}

#contact-page .contact-box h2{
    @apply normal-case;
}

.contact-box a > span {
    @apply text-white transitioning text-xl sm:text-2xl;
}

.contact-box div > span {
    @apply  text-white text-xl sm:text-2xl;
}

.contact-box a:hover > span{
    @apply text-primary transitioning;
}

#content {
    & ul {
        @apply list-disc list-inside mb-4;
    }
    a{
        @apply text-primary-500 hover:text-primary-300 transition-all
    }
}

#slider-part{
    @apply relative ;
    height: 8rem;

& .swiper-wrapper {
      @apply h-full;
  }

& .swiper-slide img{
      @apply w-48;
  }
}

#marquage li{
    @apply relative pl-4 mb-4;
    &::after{
        content: "";
        @apply w-1 h-1 bg-secondary rounded-full absolute top-3 left-0
     }
}

#autres li{
    @apply relative pl-4 mb-4 w-fit mx-auto;
&::after{
     content: "";
     @apply w-1 h-1 bg-secondary rounded-full absolute top-3 left-0
 }
}

.voir-realisation{
    @apply  invisible bg-secondary text-primary font-bold text-xl bg-opacity-0 absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center ;
}
.gallery-items.last .voir-realisation{
    @apply visible bg-opacity-75 z-1 cursor-pointer
}

.gallery-items:hover>.gallery-img img{
    @apply scale-125 transform
}

.filter>div{
    @apply w-11/12 mx-auto sm:mx-0 sm:w-auto bg-primary font-semibold text-white px-6 py-2 uppercase hover:bg-secondary hover:text-black cursor-pointer transitioning;
    & span{
          @apply text-sm
      }
}
.filter .active{
    @apply bg-secondary text-black
}
/* Footer */
footer {
    @apply text-center bg-primary-300;
}

footer h3{
    @apply text-white uppercase font-bold text-lg relative text-left mb-6;
}

#footer-top a {
    @apply text-white mb-4 relative;
}

#footer-top a:hover {
    @apply text-secondary;
}

footer p {
    @apply text-white;
}

footer .contact-box span {
    @apply text-white text-lg;
}

footer .contact-box a:hover > span{
    color: #c3e85a !important;
}

footer h3{
    @apply relative w-fit
}

.foot-col a, .foot-col p{
    @apply mb-4 text-left;
}

#footer-bottom a, #footer-bottom p, #footer-bottom span{
    @apply text-grey-100;
}

#footer-bottom a:hover {
    color: #FDFABBFF;
}

#footer-top a{
    @apply uppercase font-normal mb-2
}


/*suite amélioration*/
.link {
    cursor: pointer;
    @apply text-lg lg:text-base xl:text-lg text-primary pb-0;
    position: relative;
    white-space: nowrap;
}

.subnav{
    @apply pb-4 lg:pb-4 pt-2 lg:pt-4 lg:pl-8 lg:pr-8 lg:-left-8
}

.subnav li{
    @apply text-center lg:text-left
}
.subnav a{
    @apply hover:text-secondary text-base lg:text-sm xl:text-base text-primary pb-1 pt-2 relative;
}

.subnav a::after{
    content: "";
    background: url("/img/icones/new-arrow.svg");
    @apply absolute y-center mt-1 -left-5 w-4 h-4 text-secondary opacity-0 transitioning
}
.subnav a:hover::after{
    content: "";
    background: url("/img/icones/new-arrow.svg");
    @apply opacity-100
}
.subnav a:hover{
    @apply font-semibold
}
.link::before,
.link::after, #footer-top h3::before, #footer-top h3::after{
    position: absolute;
    width: 100%;
    height: 1px;
    @apply bg-secondary;
    top: 100%;
    left: 0;
    z-index: 999;
    pointer-events: none;
}

.link::before, #footer-top h3::before {
    content: '';
    /* show by default */
}

.link--io::before, #footer-top h3::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.link--io:hover::before, .link--io.active::before, #footer-top h3::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.link--io::after, #footer-top h3::after {
    content: '';
    top: calc(100% + 4px);
    transform-origin: 0% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.link--io:hover::after, .link--io.active::after, #footer-top h3::after {
    transform-origin: 100% 50%;
    transform: scale3d(1, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.link--io:hover, .link--io.active{
    @apply font-bold text-primary;
}


/**/
.logo-header{
    @apply w-44 xl:w-48 xxl:w-56;
    transition: ease 0.4s;
}

.logo-header-scrolled{
    @apply w-36 lg:w-32 xl:w-36
}

/* Responsive */
@screen sm {
}
@screen md {
}
@screen lg {
    header.scrollingdown {
        @apply shadow-xl;
    }

    header.scrollingdown .top-header {
        @apply shadow-none;
    }

}
@screen xl {
    .swiper-demo_prev, .swiper-demo_next,.swiper-part_prev, .swiper-part_next{
        top: 50%;
        transform: translateY(-50%);
    }

    #slider-hero {
        height: 35rem;
    }

    #slider-hero .swiper-pagination {
       @apply w-full absolute;
    }

    #slider-demo .img-content > img{
        @apply w-full h-full object-cover;
    }

    #slider-demo .swiper-pagination-bullet{
        @apply text-lg;
    }
    #slider-demo .swiper-pagination-bullet:before{
        content: "";
        left: 50%;
        top:-4.75rem;
        transform: translateX(-50%);
        @apply absolute h-10 w-10;
    }
}
@screen xxl {
}


