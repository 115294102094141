/* Boutique */
.transitioning {
    transition: .3s ease all;
}

.fil-ariane {
    @apply text-sm my-4 text-grey-500;
}

#cadre-contenu {
    @apply relative w-11/12 shadow-md mx-auto p-6;
}

#produits {
    @apply relative py-12;
}

#produits > div {
    @apply flex flex-row max-w-4xl mx-auto justify-between relative text-center
}

#produits > div > a {
    @apply w-11/12 shadow-md bg-white text-center py-4 px-2 mx-auto m-4;
}

#produits > div > a > p {
    @apply uppercase border-solid border-2 px-8 py-3 inline-block font-bold transitioning;
}

#produits > div > a:hover > p {
    @apply uppercase text-primary-500;
}

/* Panier */
#panier_etapes {
    @apply flex justify-between flex-wrap mx-auto max-w-3xl mb-4 px-10;
}

#panier_etapes > div {
    @apply text-center my-4 mx-0 text-sm;
}

#panier_etapes > div span {
    @apply bg-grey-500 rounded-full p-2 w-8 h-8 flex justify-center items-center mx-auto text-white;
}

#panier_etapes > div span.actif {
    @apply bg-primary-500;
}

/* Paiement */
#input-radio-paiement {
    @apply relative inline-block bg-grey-100 p-6 w-4/12;
}

#input-radio-paiement input {
    @apply relative inline-block;
}

#input-radio-paiement input:checked {
    @apply border-solid border-2 border-primary-500;
}

#input-radio-paiement label {
    @apply relative inline-block;
}