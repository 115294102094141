/* ----- Fonts ----- */

/* Style de base */
body {
    @apply font-body;
}

main {
    & p {
        @apply mb-2;
    }
}

/* Menu et sous-menu */
nav ul li {
    @apply relative px-2 py-1;
    & > ul {
        @apply w-max hidden;
    }
}

@screen lg {
    nav ul li {
        & > ul {
            @apply absolute block opacity-0 px-4 pb-4 invisible transition-all;

            & li > ul {
                @apply top-0 left-full;
            }
        }
        &:hover > ul {
            @apply opacity-100 visible;
        }
    }
}
